import ProjectsMain from './ProjectsMain.vue'


export default [
  {
    path: "/projects",
    name: "ProjectsMain",
    component: ProjectsMain,
    children:[
      {
        path: "dashboard",
        name: "ProjectsDashboard",
        component: () =>
          import("./ProjectsDashboard.vue"),
      },
      {
        path: "project-view/:projectSlug",
        name: "ProjectsView",
        component: () =>
          import("./project_view/ProjectView.vue"),
      },
      {
        path: "general-report",
        name: "GeneralReport",
        component: () =>
          import("./general_report/GeneralReport.vue"),
      },
      {
        path: "gantt-chart",
        name: "GanttChart",
        component: () =>
          import("./project_view/gantt-chart/ProjectGanttChart.vue"),
      },
    ]
  },
  {
    path: "/projects/categories",
    name: "ProjectsCategories",
    component: () =>
      import("./ProjectsCategories.vue"),
  },

]
