<template>
  <v-main>
    <ToolBar :links="links" :moduleName = "moduleName" />
    <div style="padding: 20px">
      <sequential-entrance fromRight>
        <router-view />
      </sequential-entrance>
    </div>
  </v-main>
</template>

<script>
import ToolBar from "../digitaltp_shared/ToolBar.vue";

export default {
  name: "FydpMain",
  components: {
    ToolBar,
  },
  data: () => ({
    moduleName: "NATIONAL FIVE YEAR DEVELOPMENT PLAN 2021/22 - 2025/26",
    links: [
      {
        name: "FYDP Dashboard",
        icon: "mdi-domain",
        routinglink: "/fydp/dashboard",
      },
      {
        name: "Priority Interventions",
        icon: "mdi-vector-intersection",
        routinglink: "/fydp/intervantions",
      },
      {
        name: "Agricultural Improvement ",
        icon: "mdi-tractor-variant",
        routinglink: "/fydp/agriculture",
      },
      {
        name: "Strategic Objectives",
        icon: "mdi-bullseye-arrow",
        routinglink: "/fydp/dashboard",
      },
      {
        name: "Private Sector Engagement ",
        icon: "mdi-handshake-outline",
        routinglink: "/fydp/dashboard",
      },
      {
        name: "Sustainable Resource",
        icon: "mdi-diamond-stone",
        routinglink: "/fydp/dashboard",
      },
      {
        name: "Environmental Resilience",
        icon: "mdi-sprout-outline",
        routinglink: "/fydp/dashboard",
      },
      {
        name: "Governance & Implementation",
        icon: "mdi-seal-variant",
        routinglink: "/fydp/dashboard",
      },
      
    ],
  }),
};
</script>




