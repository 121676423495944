import SDGMain from './SDGMain.vue'


export default [
  {
    path: "/sdg",
    name: "SDGMain",
    component: SDGMain,
    children:[
      {
        path: "dashboard",
        name: "SDGDashboard",
        component: () =>
          import(/* webpackChunkName: "Institutions" */ "./SDGDashboard.vue"),
      },
    ]
  },
  {
    path: "/sdg/international",
    name: "International",
    component: () =>
      import(/* webpackChunkName: "Institutions" */ "./International.vue"),
  },
  
]