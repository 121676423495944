var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('MainToolBar',{attrs:{"links":_vm.links}}),_c('sequential-entrance',{attrs:{"fromBottom":""}},[_c('div',{staticClass:"hero",attrs:{"fluid":""}},[_c('div',{staticClass:"main"},[_c('div',{staticClass:"cards-layer",staticStyle:{"padding-top":"40px"}},[_c('v-col',{attrs:{"md":"4","xs":"12","sm":"6"}},[_c('v-hover',{attrs:{"open-delay":"200","close-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":hover ? 5 : 0,"link":"","to":"/visions/dashboard","max-width":"90%","max-height":"120px","outlined":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('div',{staticClass:"text-overline mb-4"},[_c('v-icon',[_vm._v("mdi-database-plus")])],1),_c('v-list-item-title',{staticClass:"text-h5 mb-1"},[_vm._v(" Development Vision ")]),_c('v-list-item-subtitle',[_vm._v("Vision 2026 - 2050")])],1),_c('v-list-item-avatar',{attrs:{"tile":"","size":"90","color":"white"}},[_c('v-img',{attrs:{"max-width":"100%","src":require("@/assets/vision.png")}})],1)],1)],1)]}}])})],1)],1),_c('div',{staticClass:"cards-layer"},[_c('v-col',{attrs:{"md":"4","xs":"12","sm":"6"}},[_c('v-hover',{attrs:{"open-delay":"200","close-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":hover ? 5 : 0,"link":"","to":"/fydp/dashboard","max-width":"90%","max-height":"120px","outlined":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('div',{staticClass:"text-overline mb-4"},[_c('v-icon',[_vm._v("mdi-database-plus")])],1),_c('v-list-item-title',{staticClass:"text-h5 mb-1"},[_vm._v(" FYDP's ")]),_c('v-list-item-subtitle',[_vm._v("NFYDP for 2021/22 - 2025/26")])],1),_c('v-list-item-avatar',{attrs:{"tile":"","size":"90","color":"white"}},[_c('v-img',{attrs:{"max-width":"100%","src":require("@/assets/fydp.png")}})],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"md":"4","xs":"12","sm":"6"}},[_c('v-hover',{attrs:{"open-delay":"200","close-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":hover ? 5 : 0,"link":"","to":"/manifesto/dashboard","max-width":"90%","max-height":"120px","outlined":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('div',{staticClass:"text-overline mb-4"},[_c('v-icon',[_vm._v("mdi-magnify")])],1),_c('v-list-item-title',{staticClass:"text-h5 mb-1"},[_vm._v(" Election Manifestos ")]),_c('v-list-item-subtitle',[_vm._v("Political Parties and their Manifestos")])],1),_c('v-list-item-avatar',{attrs:{"tile":"","size":"90","color":"white"}},[_c('v-img',{attrs:{"max-width":"100%","src":require("@/assets/manifesto.jpg")}})],1)],1)],1)]}}])})],1)],1),_c('div',{staticClass:"cards-layer"},[_c('v-col',{attrs:{"md":"4","xs":"12","sm":"6"}},[_c('v-hover',{attrs:{"open-delay":"200","close-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":hover ? 5 : 0,"link":"","to":"/institution/categories","max-width":"90%","max-height":"120px","outlined":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('div',{staticClass:"text-overline mb-4"},[_c('v-icon',[_vm._v("mdi-cog-sync-outline")])],1),_c('v-list-item-title',{staticClass:"text-h5 mb-1"},[_vm._v(" MDA's & LGA's SP's ")]),_c('v-list-item-subtitle',[_vm._v("Institutional Strategic Plans (SPs) ")])],1),_c('v-list-item-avatar',{attrs:{"tile":"","size":"90","color":"white"}},[_c('v-img',{attrs:{"max-width":"100%","src":require("@/assets/institutionsp.jpg")}})],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"md":"4","xs":"12","sm":"6"}},[_c('v-hover',{attrs:{"open-delay":"200","close-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":hover ? 5 : 0,"link":"","to":"/projects/categories","max-width":"90%","max-height":"120px","outlined":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('div',{staticClass:"text-overline mb-4"},[_c('v-icon',[_vm._v("mdi-cog-sync-outline")])],1),_c('v-list-item-title',{staticClass:"text-h6 mb-1"},[_vm._v(" Flagship Projects ")]),_c('v-list-item-subtitle',[_vm._v("Strategic Development Projects")])],1),_c('v-list-item-avatar',{attrs:{"tile":"","size":"90","color":"white"}},[_c('v-img',{attrs:{"max-width":"100%","src":require("@/assets/flegship.jpg")}})],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"md":"4","xs":"12","sm":"6"}},[_c('v-hover',{attrs:{"open-delay":"200","close-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":hover ? 5 : 0,"link":"","to":"/sdg/international","max-width":"90%","max-height":"120px","outlined":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('div',{staticClass:"text-overline mb-4"},[_c('v-icon',[_vm._v("mdi-layers-triple")])],1),_c('v-list-item-title',{staticClass:"text-h5 mb-1"},[_vm._v(" International Issues ")]),_c('v-list-item-subtitle',[_vm._v("SDG's & Agenda 2063 ")])],1),_c('v-list-item-avatar',{attrs:{"tile":"","size":"90","color":"white"}},[_c('v-img',{attrs:{"max-width":"100%","src":require("@/assets/sdg.png")}})],1)],1)],1)]}}])})],1)],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }