import RelationMain from './RelationMain.vue'


export default [
  {
    path: "/relation",
    name: "RelationMain",
    component: RelationMain,
    children:[
      {
        path: "dashboard",
        name: "RelationDashboard",
        component: () =>
          import(/* webpackChunkName: "Institutions" */ "./RelationDashboard.vue"),
      },
    ]
  },
  {
    path: "/relation/countries-list",
    name: "CountriesList",
    component: () =>
      import(/* webpackChunkName: "Institutions" */ "./CountriesList.vue"),
  },
  
]