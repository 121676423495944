<template>
  <div>
    <MainToolBar :links="links" />
    <sequential-entrance fromBottom >
      <div class="hero" fluid>
        <div class="main">
          <div class="cards-layer" style="padding-top: 40px">
            <v-col md="4" xs="12" sm="6">
              <v-hover v-slot="{ hover }" open-delay="200" close-delay="200">
                <v-card
                  class="mx-auto"
                  :elevation="hover ? 5 : 0"
                  link
                  to="/visions/dashboard"
                  max-width="90%"
                  max-height="120px"
                  outlined
                >
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="text-overline mb-4">
                        <v-icon>mdi-database-plus</v-icon>
                      </div>
                      <v-list-item-title class="text-h5 mb-1">
                        Development Vision
                      </v-list-item-title>
                      <v-list-item-subtitle
                        >Vision 2026 - 2050</v-list-item-subtitle
                      >
                    </v-list-item-content>
  
                    <v-list-item-avatar tile size="90" color="white"
                      ><v-img max-width="100%" src="@/assets/vision.png"></v-img
                    ></v-list-item-avatar>
                  </v-list-item>
                </v-card>
              </v-hover>
            </v-col>
          </div>
          <div class="cards-layer">
            <v-col md="4" xs="12" sm="6">
              <v-hover v-slot="{ hover }" open-delay="200" close-delay="200">
                <v-card
                  class="mx-auto"
                  :elevation="hover ? 5 : 0"
                  link
                  to="/fydp/dashboard"
                  max-width="90%"
                  max-height="120px"
                  outlined
                >
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="text-overline mb-4">
                        <v-icon>mdi-database-plus</v-icon>
                      </div>
                      <v-list-item-title class="text-h5 mb-1">
                        FYDP's
                      </v-list-item-title>
                      <v-list-item-subtitle
                        >NFYDP for 2021/22 - 2025/26</v-list-item-subtitle
                      >
                    </v-list-item-content>
  
                    <v-list-item-avatar tile size="90" color="white"
                      ><v-img max-width="100%" src="@/assets/fydp.png"></v-img
                    ></v-list-item-avatar>
                  </v-list-item>
                </v-card>
              </v-hover>
            </v-col>
            <v-col md="4" xs="12" sm="6">
              <v-hover v-slot="{ hover }" open-delay="200" close-delay="200">
                <v-card
                  class="mx-auto"
                  :elevation="hover ? 5 : 0"
                  link
                  to="/manifesto/dashboard"
                  max-width="90%"
                  max-height="120px"
                  outlined
                >
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="text-overline mb-4">
                        <v-icon>mdi-magnify</v-icon>
                      </div>
                      <v-list-item-title class="text-h5 mb-1">
                        Election Manifestos
                      </v-list-item-title>
                      <v-list-item-subtitle
                        >Political Parties and their
                        Manifestos</v-list-item-subtitle
                      >
                    </v-list-item-content>
  
                    <v-list-item-avatar tile size="90" color="white"
                      ><v-img
                        max-width="100%"
                        src="@/assets/manifesto.jpg"
                      ></v-img
                    ></v-list-item-avatar>
                  </v-list-item>
                </v-card>
              </v-hover>
            </v-col>
          </div>
          <div class="cards-layer">
            <v-col md="4" xs="12" sm="6">
              <v-hover v-slot="{ hover }" open-delay="200" close-delay="200">
                <v-card
                  class="mx-auto"
                  :elevation="hover ? 5 : 0"
                  link
                  to="/institution/categories"
                  max-width="90%"
                  max-height="120px"
                  outlined
                >
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="text-overline mb-4">
                        <v-icon>mdi-cog-sync-outline</v-icon>
                      </div>
                      <v-list-item-title class="text-h5 mb-1">
                        MDA's & LGA's SP's
                      </v-list-item-title>
                      <v-list-item-subtitle
                        >Institutional Strategic Plans (SPs)
                      </v-list-item-subtitle>
                    </v-list-item-content>
  
                    <v-list-item-avatar tile size="90" color="white"
                      ><v-img
                        max-width="100%"
                        src="@/assets/institutionsp.jpg"
                      ></v-img
                    ></v-list-item-avatar>
                  </v-list-item>
                </v-card>
              </v-hover>
            </v-col>
            <v-col md="4" xs="12" sm="6">
              <v-hover v-slot="{ hover }" open-delay="200" close-delay="200">
                <v-card
                  class="mx-auto"
                  :elevation="hover ? 5 : 0"
                  link
                  to="/projects/categories"
                  max-width="90%"
                  max-height="120px"
                  outlined
                >
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="text-overline mb-4">
                        <v-icon>mdi-cog-sync-outline</v-icon>
                      </div>
                      <v-list-item-title class="text-h6 mb-1">
                        Flagship Projects
                      </v-list-item-title>
                      <v-list-item-subtitle
                        >Strategic Development Projects</v-list-item-subtitle
                      >
                    </v-list-item-content>
  
                    <v-list-item-avatar tile size="90" color="white"
                      ><v-img max-width="100%" src="@/assets/flegship.jpg"></v-img
                    ></v-list-item-avatar>
                  </v-list-item>
                </v-card>
              </v-hover>
            </v-col>
            <v-col md="4" xs="12" sm="6">
              <v-hover v-slot="{ hover }" open-delay="200" close-delay="200">
                <v-card
                  class="mx-auto"
                  :elevation="hover ? 5 : 0"
                  link
                  to="/sdg/international"
                  max-width="90%"
                  max-height="120px"
                  outlined
                >
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="text-overline mb-4">
                        <v-icon>mdi-layers-triple</v-icon>
                      </div>
                      <v-list-item-title class="text-h5 mb-1">
                        International Issues
                      </v-list-item-title>
                      <v-list-item-subtitle
                        >SDG's & Agenda 2063
                      </v-list-item-subtitle>
                    </v-list-item-content>
  
                    <v-list-item-avatar tile size="90" color="white"
                      ><v-img max-width="100%" src="@/assets/sdg.png"></v-img
                    ></v-list-item-avatar>
                  </v-list-item>
                </v-card>
              </v-hover>
            </v-col>
        </div>
        </div>
      </div>
    </sequential-entrance>
  </div>
</template>

<script>
import MainToolBar from "../modules/digitaltp_shared/MainToolBar .vue";
export default {
  name: "DashBoard",
  components: { MainToolBar },
  data: () => ({
    hovered: true,
    overlay: false,
  }),
};
</script>

<style>
.hero{
  margin-top: 5px;
}
.cards-layer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.main {
  position: relative;
  animation: mymove 15s infinite;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
  /* ba */
}
/* ndege.jpg */
@keyframes mymove {
  0% {
    background: linear-gradient(
        0deg,
        rgba(125, 179, 250, 0.527),
        rgba(151, 167, 187, 0.534)
      ),
      url("../assets/front-page/bwawa.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  25% {
    background: linear-gradient(
        0deg,
        
        rgba(125, 179, 250, 0.877),
        rgba(151, 167, 187, 0.534)
      ),
      url("../assets/front-page/cargo.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  50% {
    background: linear-gradient(
        0deg,
        
        rgba(125, 179, 250, 0.877),
        rgba(151, 167, 187, 0.534)
      ),
      url("../assets/front-page/bbt.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  75% {
    background: linear-gradient(
        0deg,
        
        rgba(125, 179, 250, 0.877),
        rgba(151, 167, 187, 0.534)
      ),
      url("../assets/front-page/sgr.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  100% {
    background: linear-gradient(
        0deg,
        
        rgba(125, 179, 250, 0.877),
        rgba(151, 167, 187, 0.534)
      ),
      url("../assets/front-page/ecoap.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}
</style>
