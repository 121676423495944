<template>
  <v-main>
    <ToolBar :links="links" :moduleName = "moduleName" />
    <div style="padding: 20px">
      <sequential-entrance fromRight>
        <router-view />
      </sequential-entrance>
    </div>
  </v-main>
</template>

<script>
import ToolBar from "../digitaltp_shared/ToolBar.vue";

export default {
  name: "SDGMain",
  components: {
    ToolBar,
  },
  data: () => ({
    moduleName: "Sustainable Development Goals (SDGs)",
    links: [
      {
        name: "SDG Dashboard",
        icon: "mdi-monitor-dashboard",
        routinglink: "/sdg/dashboard",
      },
      {
        name: "No Poverty",
        icon: "mdi-crowd",
        routinglink: "/sdg/",
      },
      {
        name: "Zero Hunger",
        icon: "mdi-food-turkey",
        routinglink: "/sdg/",
      },
      {
        name: "Good Health & Well-being",
        icon: "mdi-ambulance",
        routinglink: "/sdg/",
      },
      {
        name: "Quality Education",
        icon: "mdi-account-school-outline",
        routinglink: "/sdg/",
      },
      {
        name: "Gender Equality",
        icon: "mdi-human-male-female",
        routinglink: "/sdg/",
      },
      {
        name: "Clean Water & Sanitation",
        icon: "mdi-water-pump",
        routinglink: "/sdg/",
      },
      {
        name: "Affordable & Clean Energy",
        icon: "mdi-wind-power-outline",
        routinglink: "/sdg/",
      },
      {
        name: "Decent Work & Economic Growth",
        icon: "mdi-currency-usd",
        routinglink: "/sdg/",
      },
      {
        name: "Industry, Innovation & Infrastructure",
        icon: "mdi-robot-industrial-outline",
        routinglink: "/sdg/",
      },
      {
        name: "Reduced Inequality",
        icon: "mdi-scale-balance",
        routinglink: "/sdg/",
      },
      {
        name: "Sustainable Cities & Communities",
        icon: "mdi-city-variant-outline",
        routinglink: "/sdg/",
      },
      {
        name: "Responsible Consumption & Production",
        icon: "mdi-cog-sync-outline",
        routinglink: "/sdg/",
      },
      {
        name: "Climate Action",
        icon: "mdi-weather-lightning-rainy",
        routinglink: "/sdg/",
      },
      {
        name: "Life Below Water",
        icon: "mdi-dolphin",
        routinglink: "/sdg/",
      },
      {
        name: "Life on Land",
        icon: "mdi-palm-tree",
        routinglink: "/sdg/",
      },
      {
        name: "Peace & Justice Strong Institutions",
        icon: "mdi-bank",
        routinglink: "/sdg/",
      },
      {
        name: "Partnerships to the Goals",
        icon: "mdi-handshake-outline",
        routinglink: "/sdg/",
      },
    ],
  }),
};
</script>
