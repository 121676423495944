<template>
  <div>
    <v-app-bar elevation="0" app dense color="#295099" dark>
      
      <div class="d-flex align-center">
        <v-img
          alt="nembo"
          class="w-100 mr-2"
          max-height="50"
          max-width="40"
          src="@/assets/emblem.png"
        >
        </v-img>
        <v-spacer></v-spacer>

        <v-toolbar-title dark class="text">
          <span>Government-Wide National M&E Platform</span>
        </v-toolbar-title>
      </div>

      <v-spacer></v-spacer>

      <span style="color: white" class="mr-2">user@me.go.tz</span>

      <v-menu rightDrawer offset-y transition="fab-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on">
            <v-icon>mdi-menu-down</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item-group v-model="selectedItem" color="primary">
            <v-list-item v-for="(item, i) in items" :key="i" link :to="item.route">
              <v-list-item-icon>
                <v-icon :color="item.color" v-text="item.icon"> </v-icon
              ></v-list-item-icon>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  props: ["links"],
  data: () => ({
    dialog1: false,
    dialog2: false,
    hover: false,
    OpenHandoverDialog: false,
    mini: true,
    drawer: true,
    currentBoard: "My Board",
    selectedItem: 1,
    items: [
      { title: "My Profile", route: "/", icon: "mdi-account" },
      { title: "Change Password", route: "/", icon: "mdi-lock-open " },
      { title: "Logout", route: "/", icon: "mdi-power" },
    ],

    items2: [
      { title: "Help", route: "/", icon: "mdi-help-rhombus" },
      { title: "Give Feedback", route: "/", icon: "mdi-comment-quote" },
      { title: "About S3", route: "/", icon: "mdi-information" },
    ],
  }),
};
</script>

<style></style>
