<template>
  <v-main>
    <ToolBar :links="links" :moduleName = "moduleName" />
    <div style="padding: 20px">
      <sequential-entrance fromRight>
        <router-view />
      </sequential-entrance>
    </div>
  </v-main>
</template>

<script>
import ToolBar from "../digitaltp_shared/ToolBar.vue";

export default {
  name: "VisionsMain",
  components: {
    ToolBar,
  },
  data: () => ({
    moduleName: "Tanzania Joint Sectors Reviews",
    links: [
      {
        name: "JSR Dashboard",
        icon: "mdi-monitor-dashboard",
        routinglink: "/visions/dashboard",
      },
      {
        name: "Meetings Reviews",
        icon: "mdi-folder-file-outline",
        routinglink: "/settings/listsettings",
      },
      {
        name: "Potential Oportunities",
        icon: "mdi-gold",
        routinglink: "/settings/listsettings",
      },
      {
        name: "Task Forces Review",
        icon: "mdi-human-capacity-increase",
        routinglink: "/settings/listsettings",
      },
      {
        name: "Joint Sectors Strategies",
        icon: "mdi-note-text-outline",
        routinglink: "/settings/listsettings",
      }
      
    ],
  }),
};
</script>




