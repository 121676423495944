import VisionsMain from './VisionsMain.vue'


export default [
  {
    path: "/visions",
    name: "VisionsMain",
    component: VisionsMain,
    children:[
      {
        path: "dashboard",
        name: "VisionsDashboard",
        component: () =>
          import(/* webpackChunkName: "Institutions" */ "./VisionsDashboard.vue"),
      },
      {
        path: "livelihood",
        name: "InsidePage",
        component: () =>
          import(/* webpackChunkName: "Institutions" */ "./InsidePage.vue"),
      },
      {
        path: "peace",
        name: "InsidePage",
        component: () =>
          import(/* webpackChunkName: "Institutions" */ "./InsidePage.vue"),
      },
      {
        path: "governance",
        name: "InsidePage",
        component: () =>
          import(/* webpackChunkName: "Institutions" */ "./InsidePage.vue"),
      },
      {
        path: "education",
        name: "InsidePage",
        component: () =>
          import(/* webpackChunkName: "Institutions" */ "./InsidePage.vue"),
      },
      {
        path: "economy",
        name: "InsidePage",
        component: () =>
          import(/* webpackChunkName: "Institutions" */ "./InsidePage.vue"),
      },

      {
        path: "targets",
        name: "Targets",
        component: () =>
          import(/* webpackChunkName: "Institutions" */ "./Targets.vue"),
      },

      {
        path: "implementations",
        name: "Implementations",
        component: () =>
          import(/* webpackChunkName: "Institutions" */ "./Implemetations.vue"),
      },
    ]
  }
  
]