import ManifestoMain from './ManifestoMain.vue'


export default [
  {
    path: "/manifesto",
    name: "ManifestoMain",
    component: ManifestoMain,
    children:[
      {
        path: "dashboard",
        name: "ManifestoDashboard",
        component: () =>
          import(/* webpackChunkName: "Institutions" */ "./ManifestoDashboard.vue"),
      },
    ]
  }
  
]