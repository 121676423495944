import AgendaMain from './AgendaMain.vue'


export default [
  {
    path: "/agenda",
    name: "AgendaMain",
    component: AgendaMain,
    children:[
      {
        path: "dashboard",
        name: "AgendaDashboard",
        component: () =>
          import(/* webpackChunkName: "Institutions" */ "./AgendaDashboard.vue"),
      },
    ]
  }
  
]