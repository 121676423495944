import FYDPMain from './FYDPMain.vue'


export default [
  {
    path: "/fydp",
    name: "FYDPMain",
    component: FYDPMain,
    children:[
      {
        path: "dashboard",
        name: "FYDPDashboard",
        component: () =>
          import(/* webpackChunkName: "Institutions" */ "./FYDPDashboard.vue"),
      },
      {
        path: "indicators-list",
        name: "Indicators",
        component: () =>
          import(/* webpackChunkName: "Institutions" */ "./Indicators.vue"),
      },
      {
        path: "intervantions",
        name: "Intervations",
        component: () =>
          import(/* webpackChunkName: "Institutions" */ "./Intervations.vue"),
      },
      {
        path: "list-intervantions",
        name: "IntervationsList",
        component: () =>
          import(/* webpackChunkName: "Institutions" */ "./IntervationsList.vue"),
      },
      {
        path: "agriculture",
        name: "Agriculture",
        component: () =>
          import(/* webpackChunkName: "Institutions" */ "./Agriculture.vue"),
      },
    ]
  }
  
]