<template>
  <v-main class="body">
    <ToolBar :links="links" :moduleName = "moduleName" />
    <div style="padding: 20px">
      <sequential-entrance fromRight>
        <router-view />
      </sequential-entrance>
    </div>
  </v-main>
</template>

<script>
import ToolBar from "../digitaltp_shared/ToolBar.vue";

export default {
  name: "VisionsMain",
  components: {
    ToolBar,
  },
  data: () => ({
    moduleName: "Tanzania Development Vision 2025",
    links: [
      {
        name: "NDV Dashboard",
        icon: "mdi-monitor-dashboard",
        routinglink: "/visions/dashboard",
      },
      {
        name: "Quality Livelihood",
        icon: "mdi-emoticon-happy-outline",
        routinglink: "/visions/livelihood",
      },
      {
        name: "Peace, Stability & Unity",
        icon: "mdi-mother-heart",
        routinglink: "/visions/peace",
      },
      {
        name: "Good Governance",
        icon: "mdi-seal-variant",
        routinglink: "/visions/governance",
      },
      {
        name: "Education",
        icon: "mdi-school-outline",
        routinglink: "/visions/education",
      },
      {
        name: "Competitive Economy ",
        icon: "mdi-sprout-outline",
        routinglink: "/visions/economy",
      },
      
    ],
  }),
};
</script>

<style>

</style>




