<template>
  <v-main>
    <ToolBar :links="getActiveLink()" :moduleName = "moduleName" />
    <div style="padding: 20px">
      <sequential-entrance fromRight>
        <router-view />
      </sequential-entrance>
    </div>
  </v-main>
</template>

<script>
import ToolBar from "../digitaltp_shared/ToolBar.vue";
import { StorageService } from "@/services/storageService";

export default {
  name: "VisionsMain",
  components: {
    ToolBar,
  },
  data: () => ({
    moduleName: "Tanzania Development Vision 2025",
    links: {
      energy: [
        {
          name: "Dashboard",
          icon: "mdi-monitor-dashboard",
          routinglink: "/projects/dashboard",
        },
        {
          name: "JNHPP",
          icon: "mdi-water",
          routinglink: "/projects/project-view/jnhpp",
        },
        {
          name: "EACOP",
          icon: "mdi-oil",
          routinglink: "/projects/project-view/eacop",
        },
        {
          name: "REA",
          icon: "mdi-seal-variant",
          routinglink: "/projects/project-view/rea",
        },
        {
          name: "LNG",
          icon: "mdi-gas-cylinder",
          routinglink: "/projects/project-view/lng",
        }
      ]
    },
    activeSlug: ''
  }),
  methods: {
    getActiveLink() {
      return this.links[new StorageService().getItem('activeSlug')]
    }
  }
};
</script>




