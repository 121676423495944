<template>
  <v-main>
    <ToolBar :links="links" :moduleName = "moduleName" />
    <div style="padding: 20px">
      <sequential-entrance fromRight>
        <router-view />
      </sequential-entrance>
    </div>
  </v-main>
</template>

<script>
import ToolBar from "../digitaltp_shared/ToolBar.vue";

export default {
  name: "ManifestoMain",
  components: {
    ToolBar,
  },
  data: () => ({
    moduleName: "Party Manifesto (CCM Manifesto for the General Elections 2020)",
    links: [
      {
        name: "Manifesto Dashboard",
        icon: "mdi-domain",
        routinglink: "/manifesto/dashboard",
      },
      {
        name: "Economic Revolution",
        icon: "mdi-finance",
        routinglink: "/manifesto",
      },
      {
        name: "Social Services",
        icon: "mdi-human-male-board",
        routinglink: "/manifesto",
      },
      {
        name: "Science,Technology & Innovation",
        icon: "mdi-atom",
        routinglink: "/manifesto",
      },
      {
        name: "Security & Safety",
        icon: "mdi-security",
        routinglink: "/manifesto",
      },
      {
        name: "Good Governance",
        icon: "mdi-scale-balance",
        routinglink: "/manifesto",
      },
      {
        name: "Foreign Affairs",
        icon: "mdi-earth",
        routinglink: "/manifesto",
      },
      {
        name: "Regarding Zanzibar",
        icon: "mdi-island",
        routinglink: "/manifesto",
      },
      {
        name: "Other Priority Areas",
        icon: "mdi-focus-field",
        routinglink: "/manifesto",
      },
    ],
  }),
};
</script>
