<template>
  <v-app id="inspire">
    <v-main class="main-page">
      <div class="login-page">
        <carousel-3d
          :perspective="10"
          :border="0"
          :width="width"
          :height="height/0.75"
          :controls-visible="true"
          :space="100"
          :clickable="true"
          :animationSpeed="1000"
        >
          <slide v-for="(slide, i) in slides" :index="i" :key="i">
            <template>
              <img
                :data-index="index"
                class="img"
                :src="slide.src"
              />
            </template>
          </slide>
        </carousel-3d>
        <!-- <div class="main"> </div> -->
        <div class="login-container">
          <div class="login-left-side">
            <div class="login-left-side-circle">
              <img
                class="login-left-side-circle-img"
                src="@/assets/arm.png"
                alt=""
              />
            </div>
            <div class="login-right-side-text">
              <h3>Government-Wide National M&E Platform {{ width }}</h3>
            </div>
          </div>
          <div class="login-right-side">
            <div class="login-text-container">
              <div class="login-input-container">
                <v-text-field
                  dense
                  label="Username"
                  v-model="searchQuery"
                  :append-icon="'mdi-account'"
                  :outlined="true"
                  color="#15774bef"
                ></v-text-field>

                <v-text-field
                  dense
                  label="Password"
                  v-model="searchQuery"
                  type="password"
                  :append-icon="'mdi-lock'"
                  :outlined="true"
                  color="#15774bef"
                ></v-text-field>
                <div class="login-input-container-base">
                  <p link to="" class="login-right-side-forgot-text">
                    Forgot Password ?
                  </p>

                  <v-btn
                    @click="$router.push('/home')"
                    text
                    color="#19b394c5"
                    class="login-input-container-button"
                  >
                    <v-icon style="color: white">mdi-lock-open</v-icon>
                    <span style="color: white">Login</span>
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { Carousel3d, Slide } from "vue-carousel-3d";
export default {
  components: {
    Carousel3d,
    Slide,
  },
  data() {
    return {
      slides: [
        { src: require("../assets/front-page/bwawa.jpg") },
        { src: require("../assets/front-page/cargo.jpg") },
        { src: require("../assets/front-page/bbt.jpg") },
        { src: require("../assets/front-page/sgr.jpg") },
        { src: require("../assets/front-page/ecoap.jpg") },
      ],
    };
  },
  computed:{
    height(){
      var heightInPixels = window.innerHeight;
      return heightInPixels
    },
    width(){
      var heightInPixels = window.innerWidth;
      return heightInPixels
    }
  }
};
</script>

<style scoped>
.img {
  position: relative;
  animation: mymove 15s infinite;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  /* ba */
}

.login-page {
  display: flex;
  justify-content: end;
  align-items: center;
  height: 100vh;
}
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 100vh;
  background: white;
}

.login-left-side {
  box-sizing: border-box;
  width: 400px;
  height: 300px;
  background: #29509936;
  border-radius: 0px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login-right-side {
  box-sizing: border-box;
  width: 400px;
  height: 455px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  display: flex;
  justify-content: center;
}

.login-left-side-circle {
  border-radius: 50%;
  background: #ffffff;
  display: flex;
  align-items: center;
  box-shadow: 0px 6px 8px rgba(17, 17, 17, 0.25);
}

.login-left-side-circle-img {
  width: 150px;
  height: 150px;
  padding: 20px;
}

.login-right-side-img {
  margin-top: 20px;
  width: 60px;
  height: 62px;
}

.login-text-container {
  margin-top: 30px;
  flex-direction: column;
  align-items: center;
  display: flex;
  width: 100%;
}

.login-right-side-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: bolder;
  font-size: 19px;
  line-height: 39px;
  text-align: center;
  color: #264a8d;
}

.login-input-container {
  margin-top: 30px;
  width: 90%;
}
.login-right-side-forgot-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  font-size: 15px;
  color: #295099;
}
.login-input-container-base {
  justify-content: space-between;
  flex-direction: row;
  display: flex;
}

.login-input-container-button {
  border-radius: 7px;
  background-color: #295099;
}

.main-page {
  bottom: 0;
}

.main {
  position: relative;
  animation: mymove 15s infinite;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  /* ba */
}
/* ndege.jpg */
@keyframes mymove {
  0% {
    background: linear-gradient(
        0deg,
        rgba(14, 54, 107, 0.204),
        rgba(14, 54, 107, 0.204)
      ),
      url("../assets/front-page/bwawa.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  25% {
    background: linear-gradient(
        0deg,
        rgba(14, 54, 107, 0.204),
        rgba(14, 54, 107, 0.204)
      ),
      url("../assets/front-page/cargo.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  50% {
    background: linear-gradient(
        0deg,
        rgba(14, 54, 107, 0.204),
        rgba(14, 54, 107, 0.204)
      ),
      url("../assets/front-page/bbt.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  75% {
    background: linear-gradient(
        0deg,
        rgba(14, 54, 107, 0.204),
        rgba(14, 54, 107, 0.204)
      ),
      url("../assets/front-page/sgr.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  100% {
    background: linear-gradient(
        0deg,
        rgba(14, 54, 107, 0.204),
        rgba(14, 54, 107, 0.204)
      ),
      url("../assets/front-page/ecoap.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}
</style>
