import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { createProvider } from "./vue-apollo";
import vuetify from "./plugins/vuetify";
// import "@/assets/css/app.css";
import SequentialEntrance from "vue-sequential-entrance";
import "vue-sequential-entrance/vue-sequential-entrance.css";
import VueCryptojs from 'vue-cryptojs'
Vue.use(SequentialEntrance, VueCryptojs);
Vue.config.productionTip = false;

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

import ECharts from 'vue-echarts'
import { use } from 'echarts/core'

// import ECharts modules manually to reduce bundle size
import { CanvasRenderer } from 'echarts/renderers'
import { BarChart } from 'echarts/charts'
import { GridComponent, TooltipComponent } from 'echarts/components'

use([
  CanvasRenderer,
  BarChart,
  GridComponent,
  TooltipComponent
]);

// register globally (or you can do it locally)
Vue.component('v-chart', ECharts)

import Chartkick from 'vue-chartkick'

Vue.use(Chartkick)


import Carousel3d from 'vue-carousel-3d';

Vue.use(Carousel3d);


new Vue({
  router,
  store,
  apolloProvider: createProvider(),
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
