<template>
  <div>
    <v-app-bar elevation="2" app dense color="white" dark>
      <v-app-bar-nav-icon style="color:#295099" @click="mini = !mini"></v-app-bar-nav-icon>
      <div class="d-flex align-center">


        <v-toolbar-title dark class="text">
          <span style="color:#295099">{{moduleName}}</span>
        </v-toolbar-title>
      </div>

      <v-spacer></v-spacer>

      <span style="color:#295099" class="mr-2">user@me.go.tz</span>
      <!-- </v-btn> -->

      <v-menu  rightDrawer offset-y transition="fab-transition">
        <template style="color:#295099" v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on">
            <v-icon style="color:#295099">mdi-menu-down</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item-group v-model="selectedItem" color="primary">
            <v-list-item v-for="(item, i) in items" :key="i" link>
              <v-list-item-icon>
                <v-icon :color="item.color" v-text="item.icon"> </v-icon
              ></v-list-item-icon>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-app-bar>
    <SideBar  :mini="mini" :drawer="drawer" :links="links" />
    <!-- <HandOver :OpenHandoverDialog="OpenHandoverDialog" @CloseHandoverDialog="OpenHandoverDialog=false"/> -->
  </div>
</template>

<script>
import SideBar from "./SideBar.vue";
// import HandOver from './forms/HandOver.vue'
export default {
  props: ["links",'moduleName'],
  components: { SideBar },
  data: () => ({
    dialog1: false,
    dialog2: false,
    hover: false,
    OpenHandoverDialog: false,
    mini: true,
    drawer: true,
    currentBoard: "My Board",
    selectedItem: 1,
    items: [
      { title: "My Profile", route: "/", icon: "mdi-account" },
      { title: "Change Password", route: "/", icon: "mdi-lock-open " },
      { title: "Logout", route: "/", icon: "mdi-power" },
    ],

    items2: [
      { title: "Help", route: "/", icon: "mdi-help-rhombus" },
      { title: "Give Feedback", route: "/", icon: "mdi-comment-quote" },
      { title: "About S3", route: "/", icon: "mdi-information" },
    ],
  }),
};
</script>

<style></style>
