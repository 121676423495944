<template>
  <v-main>
    <ToolBar :links="links" :moduleName = "moduleName" />
    <div style="padding: 20px">
      <sequential-entrance fromRight>
        <router-view />
      </sequential-entrance>
    </div>
  </v-main>
</template>

<script>
import ToolBar from "../digitaltp_shared/ToolBar.vue";

export default {
  name: "VisionsMain",
  components: {
    ToolBar,
  },
  data: () => ({
    moduleName: "International Relations & Embassies",
    links: [
      {
        name: "IR Dashboard",
        icon: "mdi-monitor-dashboard",
        routinglink: "/relation/dashboard",
      },
      {
        name: "Agreements",
        icon: "mdi-draw",
        routinglink: "/relation/dashboard",
      },
      {
        name: "Diplomatic Visits",
        icon: "mdi-airplane-landing",
        routinglink: "/relation/dashboard",
      },
      {
        name: "Bilateral Relationship",
        icon: "mdi-earth-off",
        routinglink: "/relation/dashboard",
      },
      {
        name: "Multilateral Agreements",
        icon: "mdi-earth-plus",
        routinglink: "/relation/dashboard",
      },
      {
        name: "Diplomatic Activities",
        icon: "mdi-lectern",
        routinglink: "/relation/dashboard",
      },
      {
        name: "Crisis Management",
        icon: "mdi-alert-octagram-outline",
        routinglink: "/relation/dashboard",
      },
      {
        name: "Development Assistance",
        icon: "mdi-hand-coin-outline",
        routinglink: "/relation/dashboard",
      },
      {
        name: "Capacity Building",
        icon: "mdi-account-hard-hat-outline",
        routinglink: "/relation/dashboard",
      },
      {
        name: "Consular Services",
        icon: "mdi-face-agent",
        routinglink: "/relation/dashboard",
      }
      
    ],
  }),
};
</script>




