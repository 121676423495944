<template>
  <v-main>
    <ToolBar :links="links" :moduleName = "moduleName" />
    <div style="padding: 20px">
      <sequential-entrance fromRight>
        <router-view />
      </sequential-entrance>
    </div>
  </v-main>
</template>

<script>
import ToolBar from "../digitaltp_shared/ToolBar.vue";

export default {
  name: "AgendaMain",
  components: {
    ToolBar,
  },
  data: () => ({
    moduleName: "Agenda 2063: The Africa We Want",
    links: [
      {
        name: "Agenda 2063 Dashboard",
        icon: "mdi-monitor-dashboard",
        routinglink: "/agenda/dashboard",
      },
      {
        name: "Better Living & Well-being",
        icon: "mdi-ambulance",
        routinglink: "/agenda/",
      },
      {
        name: "Education & Innovation",
        icon: "mdi-account-school-outline",
        routinglink: "/agenda/",
      },
      {
        name: "Health & Nutrition",
        icon: "mdi-heart-pulse",
        routinglink: "/agenda/",
      },
      {
        name: "Transformed Economies",
        icon: "mdi-cash-multiple",
        routinglink: "/agenda/",
      },
      {
        name: "Modern Agriculture",
        icon: "mdi-tractor-variant",
        routinglink: "/agenda/",
      },
      {
        name: "Blue Economy",
        icon: "mdi-fish",
        routinglink: "/agenda/",
      },
      {
        name: "Sustainability & Resilience",
        icon: "mdi-shield-sync-outline",
        routinglink: "/agenda/",
      },
      {
        name: "United Africa",
        icon: "mdi-handshake-outline",
        routinglink: "/agenda/",
      },
      {
        name: "Continental Institutions",
        icon: "mdi-office-building-cog-outline",
        routinglink: "/agenda/",
      },
      {
        name: "Infrastructure Network",
        icon: "mdi-highway",
        routinglink: "/agenda/",
      },
      {
        name: "Democratic Values",
        icon: "mdi-vote",
        routinglink: "/agenda/",
      },
      {
        name: "Strong Institutions",
        icon: "mdi-bank",
        routinglink: "/agenda/",
      },
      {
        name: "Peace & Security",
        icon: "mdi-cctv",
        routinglink: "/agenda/",
      },
      {
        name: "Stable Africa",
        icon: "mdi-image-filter-center-focus-strong",
        routinglink: "/agenda/",
      },
      {
        name: "Functional APSA",
        icon: "mdi-pistol",
        routinglink: "/agenda/",
      },
      {
        name: "Cultural Renaissance",
        icon: "mdi-meditation",
        routinglink: "/agenda/",
      },
      {
        name: "Gender Equality",
        icon: "mdi-human-male-female",
        routinglink: "/agenda/",
      },
      {
        name: "Empowered Youth",
        icon: "mdi-hand-coin-outline",
        routinglink: "/agenda/",
      },
      {
        name: "Global Partnership",
        icon: "mdi-earth-plus",
        routinglink: "/agenda/",
      },
      {
        name: "Self-financing Goals",
        icon: "mdi-cash-multiple",
        routinglink: "/agenda/",
      },
      
    ],
  }),
};
</script>




