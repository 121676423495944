<template>
  <v-main>
    <ToolBar :links="links" :moduleName = "moduleName" />
    <div style="padding: 20px">
      <sequential-entrance fromRight>
        <router-view />
      </sequential-entrance>
    </div>
  </v-main>
</template>

<script>
import ToolBar from "../digitaltp_shared/ToolBar.vue";

export default {
  name: "VisionsMain",
  components: {
    ToolBar,
  },
  data: () => ({
    moduleName: "International Conventions",
    links: [
      {
        name: "Convetions Dashboard",
        icon: "mdi-domain",
        routinglink: "/fydp/dashboard",
      },
      {
        name: "UNFCCC",
        icon: "mdi-home-thermometer-outline",
        routinglink: "/fydp/dashboard",
      },
      {
        name: "CBD",
        icon: "mdi-paw-outline",
        routinglink: "/fydp/dashboard",
      },
      {
        name: "ICCPR",
        icon: "mdi-vote-outline",
        routinglink: "/fydp/dashboard",
      },
      {
        name: "Geneva Conventions",
        icon: "mdi-scale-balance",
        routinglink: "/fydp/dashboard",
      },
      {
        name: "(WTO) Agreements",
        icon: "mdi-star",
        routinglink: "/fydp/dashboard",
      },
      {
        name: "(ILO) Conventions",
        icon: "mdi-account-hard-hat-outline",
        routinglink: "/fydp/dashboard",
      },
      {
        name: "CWC",
        icon: "mdi-chemical-weapon",
        routinglink: "/fydp/dashboard",
      },
      {
        name: "UNCLOS",
        icon: "mdi-waves",
        routinglink: "/fydp/dashboard",
      },
      {
        name: "World Heritage Convention",
        icon: "mdi-image-filter-hdr",
        routinglink: "/fydp/dashboard",
      },
      {
        name: "ICERD",
        icon: "mdi-code-greater-than-or-equal",
        routinglink: "/fydp/dashboard",
      },
      
    ],
  }),
};
</script>




