<template>
  <v-main>
    <ToolBar :links="links" :moduleName = "moduleName" />
    <div style="padding: 20px">
      <sequential-entrance fromRight>
        <router-view />
      </sequential-entrance>
    </div>
  </v-main>
</template>

<script>
import ToolBar from "../digitaltp_shared/ToolBar.vue";

export default {
  name: "VisionsMain",
  components: {
    ToolBar,
  },
  data: () => ({
    moduleName: "Institution's Strategic Plan (SP) Monitoring & Evaluation (M&E)",
    links: [
      {
        name: "NDV Dashboard",
        icon: "mdi-domain",
        routinglink: "/institution/dashboard",
      },
      {
        name: "Goals, Objectives,Targets & Indicators",
        icon: "mdi-bullseye-arrow",
        routinglink: "/institution/goals",
      },
      {
        name: "Define KPI's & Metrics",
        icon: "mdi-scale-balance",
        routinglink: "/institution/dashboard",
      },
      {
        name: "Linkage & Alignments",
        icon: "mdi-link-variant-plus",
        routinglink: "/institution/dashboard",
      },
      {
        name: "Reporting & Data Inputs",
        icon: "mdi-file-replace-outline",
        routinglink: "/institution/dashboard",
      },
      {
        name: "Performance Analysis",
        icon: "mdi-google-analytics",
        routinglink: "/institution/dashboard",
      },
      {
        name: "Progress & Outcomes Tracking",
        icon: "mdi-magnify-expand",
        routinglink: "/institution/dashboard",
      },
      {
        name: "Feedback Mechanism",
        icon: "mdi-comment-quote-outline",
        routinglink: "/institution/dashboard",
      },
      {
        name: "SP Adaptation Measures",
        icon: "mdi-delta",
        routinglink: "/institution/dashboard",
      },
      {
        name: "Accountability Strategy",
        icon: "mdi-account-reactivate-outline",
        routinglink: "/institution/dashboard",
      },
      {
        name: "SP Benchmarking",
        icon: "mdi-select-compare",
        routinglink: "/institution/dashboard",
      },
      {
        name: "Risk Management",
        icon: "mdi-link-box-outline",
        routinglink: "/institution/dashboard",
      },
      {
        name: "Quality Assuarance",
        icon: "mdi-link-box-outline",
        routinglink: "/institution/dashboard",
      },
      {
        name: "Evaluate SP Effectiveness",
        icon: "mdi-chart-timeline-variant-shimmer",
        routinglink: "/institution/dashboard",
      }
    ],
  }),
};
</script>




