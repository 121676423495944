import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Home from "../views/Home.vue";
import projectRouters from '../modules/digitaltp_projects/projectRouters.js'
import settingsRouters from '../modules/digitaltp_settings/settingsRouters.js'
import sdgRouters from '../modules/me_sdg/sdgRouters.js'
import manifestoRouters from '../modules/me_manifesto/manifestoRouters.js'
import sectorialRouters from '../modules/me_ministries/sectorialRouters.js'
import fydpRouters from '../modules/me_fydp/fydpRouters.js'
import visionsRouters from '../modules/me_vision/visionsRouters.js'
import agendaRouters from '../modules/me_afagenda/agendaRouters.js'
import institutionRouters from '../modules/me_institutions/institutionRouters.js'
import relationRouters from '../modules/me_relations/relationRouters.js'
import conventionsRouters from '../modules/me_conventions/conventionsRouters.js'
import projectsRouters from '../modules/me_projects/projectsRouters.js'
import jointsectorsRouters from '../modules/me_jointsectors/jointsectorsRouters.js'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  ...projectRouters,
  ...settingsRouters,
  ...sdgRouters,
  ...manifestoRouters,
  ...sectorialRouters,
  ...fydpRouters,
  ...visionsRouters,
  ...agendaRouters,
  ...institutionRouters,
  ...relationRouters,
  ...conventionsRouters,
  ...projectsRouters,
  ...jointsectorsRouters
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

