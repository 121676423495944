import InstitutionMain from './InstitutionMain.vue'


export default [
  {
    path: "/institution",
    name: "InstitutionMain",
    component: InstitutionMain,
    children:[
      {
        path: "dashboard",
        name: "InstitutionDashboard",
        component: () =>
          import(/* webpackChunkName: "Institutions" */ "./InstitutionDashboard.vue"),
      },
      {
        path: "goals",
        name: "InstitutionGoalsList",
        component: () =>
          import(/* webpackChunkName: "Institutions" */ "./InstitutionGoalsList.vue"),
      },
      {
        path: "objectives-targets",
        name: "InstitutionObjectivesList",
        component: () =>
          import(/* webpackChunkName: "Institutions" */ "./InstitutionObjectivesList.vue"),
      },
      {
        path: "targets-list",
        name: "InstitutionTargetsList",
        component: () =>
          import(/* webpackChunkName: "Institutions" */ "./InstitutionTargetsList.vue"),
      },
      {
        path: "indicators-categories",
        name: "InstitutionIndicatorsCategories",
        component: () =>
          import(/* webpackChunkName: "Institutions" */ "./InstitutionIndicatorsCategories.vue"),
      },
      {
        path: "indicators-list",
        name: "InstitutionIndicatorsList",
        component: () =>
          import(/* webpackChunkName: "Institutions" */ "./InstitutionIndicatorsList.vue"),
      },
    ]
  },
  
  {
    path: "/institution/categories",
    name: "InstitutionCategories",
    component: () =>
      import(/* webpackChunkName: "Institutions" */ "./InstitutionCategories.vue"),
  },
  {
    path: "/institution/institution-list",
    name: "InstitutionList",
    component: () =>
      import(/* webpackChunkName: "Institutions" */ "./InstitutionList.vue"),
  },
  
]


