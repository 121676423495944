import ConventionsMain from './ConventionsMain.vue'


export default [
  {
    path: "/conventions",
    name: "ConventionsMain",
    component: ConventionsMain,
    children:[
      {
        path: "dashboard",
        name: "ConventionsDashboard",
        component: () =>
          import(/* webpackChunkName: "Institutions" */ "./ConventionsDashboard.vue"),
      },
    ]
  }
  
]