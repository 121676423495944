<template>
  <v-main>
    <ToolBar :links="links" :moduleName = "moduleName" />
    <div style="padding: 20px">
      <sequential-entrance fromRight>
        <router-view />
      </sequential-entrance>
    </div>
  </v-main>
</template>

<script>
import ToolBar from "../digitaltp_shared/ToolBar.vue";

export default {
  name: "MinistryMain",
  components: {
    ToolBar,
  },
  data: () => ({
    moduleName: "Ministries and Sectors",
    links: [
      {
        name: "Ministries Dashboard",
        icon: "mdi-domain",
        routinglink: "/ministry/dashboard",
      },
      {
        name: "PO-PSMGG",
        icon: "mdi-account-group-outline",
        routinglink: "/settings/listsettings",
      },
      {
        name: "MOD",
        icon: "mdi-tank",
        routinglink: "/settings/listsettings",
      },
      {
        name: "PO-RALG",
        icon: "mdi-family-tree",
        routinglink: "/settings/listsettings",
      },
      {
        name: "VP-MM",
        icon: "mdi-forest",
        routinglink: "/settings/listsettings",
      },
      {
        name: "PMO-LYED",
        icon: "mdi-human-dolly",
        routinglink: "/settings/listsettings",
      },
      {
        name: "MWT",
        icon: "mdi-dump-truck",
        routinglink: "/settings/listsettings",
      },
      {
        name: "MOH",
        icon: "mdi-heart-pulse",
        routinglink: "/settings/listsettings",
      },
      {
        name: "PMO-SBU",
        icon: "mdi-poll",
        routinglink: "/settings/listsettings",
      },
      {
        name: "MOF",
        icon: "mdi-cash-multiple",
        routinglink: "/settings/listsettings",
      },
      {
        name: "MOHA",
        icon: "mdi-account-tie-hat-outline",
        routinglink: "/settings/listsettings",
      },
      {
        name: "MFAIC",
        icon: "mdi-earth",
        routinglink: "/settings/listsettings",
      },
      {
        name: "MOA",
        icon: "mdi-tractor-variant",
        routinglink: "/settings/listsettings",
      },
      {
        name: "MOLF",
        icon: "mdi-fish",
        routinglink: "/settings/listsettings",
      },
      {
        name: "MOM",
        icon: "mdi-diamond-stone",
        routinglink: "/settings/listsettings",
      },
      {
        name: "MoEST",
        icon: "mdi-account-school-outline",
        routinglink: "/settings/listsettings",
      },
      {
        name: "MICIT",
        icon: "mdi-television-classic",
        routinglink: "/settings/listsettings",
      },
      {
        name: "MOE",
        icon: "mdi-transmission-tower",
        routinglink: "/settings/listsettings",
      },
      {
        name: "MoCLA",
        icon: "mdi-bookshelf",
        routinglink: "/settings/listsettings",
      },
      {
        name: "WMJJWM",
        icon: "mdi-human-male-female",
        routinglink: "/settings/listsettings",
      },
      {
        name: "MIT",
        icon: "mdi-ferry",
        routinglink: "/settings/listsettings",
      },
      {
        name: "MOW",
        icon: "mdi-water-pump",
        routinglink: "/settings/listsettings",
      },
      {
        name: "MOCAS",
        icon: "mdi-basketball",
        routinglink: "/settings/listsettings",
      },
      {
        name: "MNRT",
        icon: "mdi-image-filter-hdr",
        routinglink: "/settings/listsettings",
      }
      
    ],
  }),
};
</script>




